import type { Ref } from 'vue'
import { reset } from '@formkit/core'
import type { LeaseDocumentValue } from '@ui/formkit/inputs/leaseDocumentInput/types'
import {
  useFieldSubmitHandler,
  type ILeaseVariation,
  type IUpdateLeaseVariationRequest,
  useApiAffectedFields,
  resetAffectedField,
} from '@register'
import { AmendmentEditFormSymbol } from '../constants'
import type { IUpdateLeaseVariationForm } from '@register/components/Amendment/Edit/types'
import { useFormKitContextById } from '@formkit/vue'

export const useAmendmentFieldSubmit = (variation: Ref<ILeaseVariation>) => {
  /* === LEASE & DIVISION === */
  const divisionId = getCurrentDivisionId()
  const leaseId = getCurrentLeaseId()
  /* === LEASE & DIVISION === */

  /* === MUTATION === */
  const { mutateAsync: saveVariation } = useApiLeaseVariationsMutation(
    divisionId,
    leaseId,
  )
  /* === MUTATION === */

  const { data } = useApiAffectedFields(divisionId)
  const affectedFields = computed(() => data.value ?? {})

  // FORM
  const fieldValue = ref<LeaseDocumentValue>()
  const formModelValue = inject<Ref<IUpdateLeaseVariationForm>>(
    AmendmentEditFormSymbol,
  )!

  const onSubmit = useFieldSubmitHandler<LeaseDocumentValue, ILeaseVariation>(
    'variation',
    (data) =>
      saveVariation({
        variationId: variation.value.id,
        body: createPayload(data),
      }) as Promise<ILeaseVariation>,
    {
      onSuccess: (response, { node }) => {
        // Sync value
        if (!node || !node.context) return

        const fieldId = response.leaseField.id

        if (node.context.isApproved && response.value) {
          node.context.preventNextRequest = true
          const value: LeaseDocumentValue = {
            value: node.context.fieldConfig.toAPI(response.value),
            bounds: response.bounds
              ? {
                  bounds: response.bounds ?? [],
                  documentId: response.documentId,
                }
              : null,
            isApproved: true,
          }
          node.props.initial = node.props._init = value
          reset(node, value)
        }

        if (fieldId in affectedFields.value) {
          for (const affectedFieldId of affectedFields.value[fieldId]) {
            resetAffectedField(affectedFieldId)
          }
        }
      },
    },
  )

  watch(
    () => formModelValue.value.startDate,
    () => {
      onSubmit(fieldValue.value)
    },
  )

  return {
    fieldValue,
    onSubmit,
  }

  function createPayload(
    data: LeaseDocumentValue,
  ): IUpdateLeaseVariationRequest {
    return {
      startDate: formModelValue.value.startDate,
      // isApproved: formModelValue.value.isApproved,
      isApproved: data.isApproved,
      value: data.value,
      bounds: data.bounds?.bounds,
    }
  }
}
